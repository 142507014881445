import { makeAutoObservable } from "mobx"
import { globalInstance } from "utils/singleton"
import NetworkService from "../services/network-service"
import { AlertTypes, IAlert, INotification } from "./alerts-store.types"
import mainStore from "./main-store"
import userStore from "./user-store"
import BrowserNotificationsService from "../services/browser-notifications-service"

// const CACHED_API_URL = location.href.includes("http://localhost") ? "http://localhost:36912/frontend" : "https://storage.googleapis.com/cdn-data-hypeindex/frontend"
// const CACHED_API_URL = location.href.includes("http://localhost") ? "http://localhost:36912/frontend" : "https://serve.hypeindex.io/frontend"
const CACHED_API_URL = location.href.includes("http://localhost") ? "http://localhost:3001/frontend" : "https://serve.hypeindex.io/frontend"

class AlertsStore {
  constructor() {
    makeAutoObservable(this)
    const browserPush = localStorage.getItem("browser-push")
    this.browserPushNotifications = JSON.parse(browserPush)
  }

  public setupAlertsPopupOpen: boolean = false
  public addAlertPopupOpen: boolean = false
  public addAlertPopupDefaultSelectedAsset: string = ""
  public allUserAlerts: IAlert[] = []
  public allUserNotifications: INotification[] = []
  public browserPushNotifications: boolean = false

  public async getAllUserAlerts() {
    if (!userStore.currentUserData?.firebaseId) return

    await mainStore.getAllAssets()

    const response = await NetworkService.fetch(`${CACHED_API_URL}/alerts/${userStore.currentUserData.firebaseId}.json`)
    const migrated = response.map(al => ({ ...al, asset: mainStore.allAssets.find(as => as.id === al.assetId) }))
    this.setUserAlerts(migrated)
  }

  public async getAllUserNotifications() {
    if (!userStore.currentUserData?.firebaseId) return

    await this.getAllUserAlerts()

    const response = await NetworkService.fetch(`${CACHED_API_URL}/notifications/${userStore.currentUserData.firebaseId}.json`)
    const migrated = response.map(noti => {
      const alert = this.allUserAlerts.find(al => al.id === noti.alertId)
      return {
        ...noti, alert,
        // todo set the actual value from server
        actualValue: noti.notificationText.split("value is ")[1].split(" for date:")[0]
      }
    })

    if (this.allUserNotifications.length && this.browserPushNotifications) {
      const newNotifications = migrated.filter(noti => !this.allUserNotifications.some(n => n.id === noti.id))
      newNotifications.slice(0, 1).forEach(noti => {
        BrowserNotificationsService.notify(
          `HYPEINDEX`, {
            icon: "/favicon.png",
            body: `${noti.alert.asset.symbol} - ${noti.alert.fieldType} ${AlertTypes[noti.alert.alertType].toLowerCase()} ${noti.alert.testValue} (${noti.actualValue})`
          }
        )
      })
    }

    this.setUserNotifications(migrated)
  }

  public async createUserAlert({ asset, field, operator, value }){
    const body = {
      field_type: field,
      alert_type: operator,
      test_value: value,
      asset_id: asset,
      firebase_id: userStore.currentUserData.firebaseId
    }

    //todo need to add /frontend prefix
    const response = await NetworkService.fetch(`${CACHED_API_URL}/alerts.json`, { method: "POST", body })
    // const response = await NetworkService.fetch(`https://serve.hypeindex.io/alerts.json`, { method: "POST", body })
    const newAlert = {
      ...response.alert,
      asset: mainStore.allAssets.find(as => as.id === response.alert.assetId)
    }
    this.allUserAlerts.push(newAlert)
    this.setUserAlerts(this.allUserAlerts)
  }

  public async removeUserAlert({ alert }){
    await NetworkService.fetch(`${CACHED_API_URL}/alerts/${alert}`, { method: "DELETE" })
    // await NetworkService.fetch(`https://serve.hypeindex.io/alerts/${alert}`, { method: "DELETE" })
    const alertObj = this.allUserAlerts.find(al => al.id === alert)
    const alertIdx = this.allUserAlerts.indexOf(alertObj)
    this.allUserAlerts.splice(alertIdx, 1)
    this.setUserAlerts(this.allUserAlerts)
  }

  public async setNotificationAsRead({notification}) {
    const notificationObj = this.allUserNotifications.find(noti => noti.id === notification)
    if (notificationObj.isRead) return
    await NetworkService.fetch(`${CACHED_API_URL}/${notification}/notification_read`)
    notificationObj.isRead = true
  }

  public async setAllNotificationAsRead() {
    await NetworkService.fetch(`${CACHED_API_URL}/${userStore.currentUserData?.firebaseId}/all_notifications_read`)
    this.allUserNotifications.forEach(noti => noti.isRead = true)
  }

  public async toggleAlert({ alert }){
    await NetworkService.fetch(`${CACHED_API_URL}/${alert}/toggle_alert.json`)
  }

  public setUserAlerts(alerts) {
    this.allUserAlerts = alerts
  }

  public setUserNotifications(notifications) {
    this.allUserNotifications = notifications
    localStorage.setItem("user_notifications", JSON.stringify(notifications))
  }

  public setAddAlertPopup(options: { open: boolean, selectedAsset?: string }) {
    this.addAlertPopupOpen = options.open
    this.addAlertPopupDefaultSelectedAsset = options.selectedAsset
  }

  public setSetupAlertsPopup(options: { open: boolean, selectedAsset?: string }) {
    this.setupAlertsPopupOpen = options.open
  }

  public toggleBrowserPushNotifications() {
    this.browserPushNotifications = !this.browserPushNotifications
    localStorage.setItem("browser-push", this.browserPushNotifications.toString())
  }
}

export default globalInstance("alertsStore", AlertsStore)
